/* You can add global styles to this file, and also import other style files */

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');
@import '~admin-lte/dist/css/adminlte.min.css';
// @import '~ngx-toastr/toastr';

body {
  background-color: #000;
  color: #fff;
  font-family: 'Roboto';
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
a,
a label {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.width100 {
  width: 100% !important;
}

.width50 {
  width: 50% !important;
}

.switch {
  position: relative;
  width: 2.5rem;
  height: 1.563rem;
  margin: 0;
  input {
    opacity: 0;

    width: 0;

    height: 0;
  }

  .slider {
    position: absolute;

    cursor: pointer;

    top: -0.013rem;

    left: 0;

    right: 0;

    bottom: 0;

    // background-color: #218838;
    background-color: #ccc;

    -webkit-transition: 0.4s;

    transition: 0.4s;

    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 1px #ccc;

    &::before {
      position: absolute;
      content: '';
      height: 1.375rem;
      width: 1.375rem;
      left: 0.125rem;
      bottom: 0.125rem;
      // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 1px #ccc;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 24px;

      &::before {
        border-radius: 50%;
      }
    }
  }

  input:checked + .slider {
    // background-color: #ccc;
    background-color: #218838;
  }

  input:focus + .slider {
    // box-shadow: 0 0 1px #ccc;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);

    transform: translateX(14px);
  }
}

.user-icon {
  font-size: 50px;
  margin-bottom: 1.5rem;
  width: 160px;
  text-align: center;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background: #343342;
  display: flex;
  align-items: center;
  justify-content: center;
}

app-mic-test button {
  margin-right: 10px !important;
  margin-bottom: 10px;
}

.focused-username {
  background: #000;
  padding: 0 10px;
}
.user-details {
  //display: flex;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 160px);
  margin-bottom: 5px;
  overflow-y: auto;
  width: 100%;
  flex-wrap: nowrap;

  .user-info-wrapper {
    max-width: 333px;
    width: 100%;
    text-align: center;
    display: grid;
    place-items: center;
    margin: 8px;
  }

  .user-icon {
    font-size: 50px;
    margin-bottom: 1.5rem;
    width: 160px;
    text-align: center;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: #343342;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .call-info {
    font-weight: 400;
    font-size: 20px;
    color: #71717a;
  }
  .user-name {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }
  .counter {
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    opacity: 0.75;
  }
}

.right-side-wrapper {
  .participants-wrapper { 
    video {
      border-radius: 10px;
      border: 1px solid #aaa;
    }
  }
  .local-video-wrapper {
    margin: 0 40px;
    margin-top: 30px;
    video {
        border: 1px solid #aaa;
        border-radius: 10px;
        height: 18vh;
        object-fit: cover;
    }
}
  .local-preview-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18vh;
    font-size: 42px;
    font-weight: 500;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    background: #343342;
    border-radius: 10px;
    border: 1px solid #aaa;
  }

  .local-names {
    font-size: 12px;
    font-weight: 500;
    text-align: center !important;
    color: #fff;
    margin-top: 5px;
    span{      
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 60px;
    }
  }

  video {
    width: 100%;
  }
  $color: white;
  .dominant-speaker-video > video {
    border: solid 2px $color;
    width: 100%;
    transition: all 0.7s ease;
    animation: ripple 1.5s linear infinite;
  }
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba($color, 0.3),
      0 0 0 1px rgba($color, 0.3),
      0 0 0 3px rgba($color, 0.3),
      0 0 0 5px rgba($color, 0.3)
    }
    100% {
      box-shadow: 0 0 0 0 rgba($color, 0.3),
      0 0 0 4px rgba($color, 0.3),
      0 0 0 20px rgba($color, 0),
      0 0 0 30px rgba($color, 0)
    }
    
  }
}

// mobile-view of video calling css 
@media only screen and (max-width : 767px){
  .remote-wrapper{
    height: 100% !important;
  }
  .video-contain-wrapper{
    flex-direction: column;
  }
  .video-contain-wrapper{
    .left-side-wrapper{
      width: 100vw !important;
      height: calc(100% - 130px);
    }
  }
  .twilio-wrapper{
    .twilio-container{
      .icon-wrapper{
        width: 100% !important;
      }
    }  
  } 
  #remote-video , #remote-screen{
    min-height: unset !important;
    // max-height: 60vh !important;
    width: 100% !important;
    // height: 100% !important;
    // height: calc(100vh - 5rem) !important;
    height: 100% !important;
    // padding-top: 105px !important;
    padding-bottom: 0px !important;
  }
  .video-contain-wrapper{
    height: calc(100% - 150px) !important;
    .right-side-wrapper{
      width: 100% !important;
      flex-direction: row !important;
      .local-names{
        font-size: 10px;
        font-weight: 400;
      }
      .other-participants{
        display: flex;
        height: 100% !important;
        overflow-y: hidden !important;
        overflow-x: auto ;
        gap: 20px;
        margin: 0 10px;
        padding: 15px;
        &::-webkit-scrollbar {
          display: none;
        }
        .participants-wrapper{

          // width: 130px;
          .local-preview-text{
            margin: 0;
            width: 80px;
            min-height: 80px !important;
            height: 80px !important;
            border-radius: 10px;
          }
        }
        
      }
    } 
  } 
  .video-contain-wrapper{
    .right-side-wrapper{
        .local-wrapper{
          position: absolute;
          top: 60px;
          right: 12px;
          height: auto !important;
          margin: 0;
          .local-preview-text {
            margin: 0 !important;
            width: 80px !important;
            min-height: 80px !important;
            border-radius: 10px;
          }
        }
      }
    }
    
    .twilio-wrapper{
      .twilio-container{
        .icon-wrapper{
          .icon{
            width: 40px !important;
            height: 40px !important;
            font-size: 15px;
          }
        } 
      } 
    } 
    .track_wrapper{
      margin: 0 !important;
      width: 80px !important;
      min-height: 80px !important;
      height: 80px !important;
      border-radius: 20px;
      video{
        width: 80px;
        min-height: 80px !important;
        height: 80px !important;
      }
    }
    .participants-wrapper div:first-child{
      display: flex; width: 100%;
    }


    // for starting screen video and info css 
    .for-mobile-flex-wrap{
      flex-direction: column !important;
      .mobile-grid-content{
        &.w-50{
          width: 100% !important;
        }
      }
    }
    .mobile-video-height{
      height: 350px !important;
    }
    .local-wrapper{
      .local-video-wrapper{
        margin: 0 !important;
        video{
          width: 80px !important;
          min-height: 80px !important;
          max-width: 80px !important;
          height: 80px !important;
        }
      }
    }
}

#toast-container .toast-warning{
  z-index: 9999 !important;
  position: relative;
}

.blur-checkbox {
  height: 17px;
  width: 17px;
}
.blur-label {
  font-size: 1rem;
}

@media only screen and (max-width:767px){
  .mobile-network{
    margin-left: 8px !important;
  }
  .counter{
    margin-left: 0 !important;
  }
}